.btn {
  padding: 10px;
}

.custombutton {
  width: 100% !important;
  margin-top: 5px;
}

.custombuttongroup {
  padding: 0.25rem;
  width: 90% !important;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px 30px;
}

@media (min-width: 576px) {
  .custombuttongroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .custombuttongroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .custombuttongroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

.customheading2 {
  background-image: linear-gradient(to right, #553c9a 45%, #ee4b2b);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
